import { useRoutes } from 'react-router-dom'

import Home from '@/views/Home/index'
import Login from '@/views/Auth/Login'
import ApplyCandidateForm from '@/views/Job/add-candidate';
import ApplyCandidateFormBulk from '@/views/Job/bulk-upload';
import JobDetails from '@/views/Job/job-details'
import PrivateRoute from '@/components/PrivateRoute'

export default function Router() {
  return useRoutes([
    {
      path: '/',
      element: <PrivateRoute element={<Home />} />,
    },
    {
      path: '/home',
      element: <PrivateRoute element={<Home />} />,
    },
    {
      path: '/jobs',
      element: <PrivateRoute element={<Home />} />,
    },
    {
      path: '/login',
      element: <Login />,
    },
    {
      path: '/job/:id',
      element: <PrivateRoute element={<JobDetails />} />,
    },
    {
      path: '/job/:id/add-candidate',
      element: <PrivateRoute element={<ApplyCandidateForm />} />,
    },
    {
      path: '/job/:id/add-bulk-candidates',
      element: <PrivateRoute element={<ApplyCandidateFormBulk />} />,
    },
  ])
}
