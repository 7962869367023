import React, { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Input } from 'baseui/input';
import { Card } from 'baseui/card';
import { Grid, Cell } from 'baseui/layout-grid';
import { Button } from 'baseui/button';
import { HeadingSmall, ParagraphSmall } from 'baseui/typography';
import { toaster } from 'baseui/toast';
import Papa from 'papaparse'; // CSV parser
import { usePost } from '@/hooks/apiHooks';
import { FileUploader } from 'baseui/file-uploader';
import { FormControl } from 'baseui/form-control';
import { TableBuilder, TableBuilderColumn } from 'baseui/table-semantic';
import { Spinner } from 'baseui/spinner';
import { NavigateBack } from '@/components/navigation/navigate-back';

const ApplyCandidateFormBulk: React.FC = () => {
  const { id: jobId } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const [csvData, setCsvData] = useState<any[]>([]); // Store parsed CSV data
  const [cellErrors, setCellErrors] = useState<Record<string, string>>({}); // Store errors for each cell
  const [loading, setLoading] = useState(false); // Track if loading
  const submitMutation = usePost(`agency-api/job/${jobId}/apply-candidate`);

  // Validation function for each row of CSV
  const validateCsvRow = (candidate: any, rowIndex: number): Record<string, string> => {
    const rowErrors: Record<string, string> = {};

    if (!candidate.name) rowErrors.name = 'Name is required';
    if (!candidate.email) {
      rowErrors.email = 'Email is required';
    } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(candidate.email)) {
      rowErrors.email = 'Invalid email format';
    }
    if (!candidate.phone) rowErrors.phone = 'Phone number is required';
    if (!candidate.countryId) rowErrors.countryId = 'Country is required';
    if (!candidate.expectedSalary) rowErrors.expectedSalary = 'Expected salary is required';
    if (!candidate.resumeLink) rowErrors.resumeLink = 'Resume link is required';

    // Add row-specific errors
    Object.keys(rowErrors).forEach((key) => {
      setCellErrors((prev) => ({
        ...prev,
        [`${rowIndex}-${key}`]: rowErrors[key],
      }));
    });

    return rowErrors;
  };

  // CSV Upload handler and validation triggered by FileUploader
  const handleCsvFile = (file: File) => {
    setLoading(true); // Show loading spinner

    Papa.parse(file, {
      header: true,
      skipEmptyLines: true,
      complete: (results) => {
        const candidates = results.data;
        const newCellErrors: Record<string, string> = {};

        // Validate each row after parsing
        candidates.forEach((candidate, rowIndex) => {
          const rowErrors = validateCsvRow(candidate, rowIndex);
          if (Object.keys(rowErrors).length > 0) {
            Object.assign(newCellErrors, rowErrors);
          }
        });

        setCsvData(candidates); // Set the CSV data
        setCellErrors(newCellErrors); // Set cell-level errors
        setLoading(false); // Hide loading spinner

        if (Object.keys(newCellErrors).length === 0) {
          toaster.positive('CSV data is valid!', {});
        } else {
          toaster.negative('CSV contains errors. Please resolve before submitting.', {});
        }
      },
      error: () => {
        setLoading(false);
        toaster.negative('Failed to parse CSV.', {});
      },
    });
  };

  // Handle inline edits in the table
  const handleCellChange = (rowIndex: number, columnId: string, value: string) => {
    const updatedData = [...csvData];
    updatedData[rowIndex][columnId] = value;
    setCsvData(updatedData);

    // Revalidate the row after editing
    const rowErrors = validateCsvRow(updatedData[rowIndex], rowIndex);
    if (Object.keys(rowErrors).length === 0) {
      const newCellErrors = { ...cellErrors };
      delete newCellErrors[`${rowIndex}-${columnId}`]; // Remove the error for this cell if valid
      setCellErrors(newCellErrors);
    }
  };

  const handleCsvSubmit = () => {
    if (Object.keys(cellErrors).length > 0) {
      toaster.negative('Please resolve all errors before submitting.', {});
      return;
    }

    csvData.forEach(async (candidate, index) => {
      const formPayload = new FormData();
      Object.keys(candidate).forEach((key) => {
        formPayload.append(key, candidate[key]);
      });

      try {
        await submitMutation.mutateAsync(formPayload, {
          onSuccess: () => {
            toaster.positive(`Candidate in row ${index + 1} added successfully.`, {});
          },
          onError: () => {
            toaster.negative(`Failed to add candidate in row ${index + 1}.`, {});
          },
        });
      } catch (error) {
        toaster.negative(`Error submitting row ${index + 1}.`, {});
      }
    });

    navigate(`/job/${jobId}`);
  };

  return (
    <>
      <NavigateBack route={`/job/${jobId}`} text={`Go back to job`} />
      <Card overrides={{ Root: { style: { padding: '20px', marginTop: '20px' } } }}>
        <Grid>
          <Cell span={12}>
            <HeadingSmall style={{ textAlign: 'center', marginBottom: '20px' }}>
              Bulk Upload Candidates
            </HeadingSmall>
          </Cell>

          {/* CSV Upload */}
          <Cell span={12}>
            <FormControl
              label="Upload CSV File"
              caption="Only CSV files are allowed. Ensure each row includes a resume link."
            >
              <FileUploader
                onDrop={(acceptedFiles) => {
                  if (acceptedFiles.length > 0) {
                    handleCsvFile(acceptedFiles[0]);
                  }
                }}
                onDropRejected={() => setCsvData([])}
                accept=".csv"
                multiple={false}
                name="csv"
              />
            </FormControl>
          </Cell>

          {/* Loading Spinner */}
          {loading && (
            <Cell span={12}>
              <div style={{ display: 'flex', justifyContent: 'center', margin: '20px 0' }}>
                <Spinner size={50} />
              </div>
            </Cell>
          )}

          {/* Show parsed CSV data in a Base Web Table */}
          {!loading && csvData.length > 0 && (
            <Cell span={12}>
              <TableBuilder data={csvData}>
                {Object.keys(csvData[0]).map((key) => (
                  <TableBuilderColumn header={key} key={key}>
                    {(row, rowIndex) => (
                      <div>
                        <Input
                          // @ts-ignore
                          value={row[key]}
                          onChange={(e) =>
                            // @ts-ignore
                            handleCellChange(rowIndex, key, e.target.value)
                          }
                          overrides={{
                            Root: {
                              style: {
                                backgroundColor: cellErrors[`${rowIndex}-${key}`]
                                  ? '#FFEDED'
                                  : 'white', // Highlight invalid cells
                              },
                            },
                          }}
                        />
                        {cellErrors[`${rowIndex}-${key}`] && (
                          <ParagraphSmall color="negative" margin="0">
                            {cellErrors[`${rowIndex}-${key}`]}
                          </ParagraphSmall>
                        )}
                      </div>
                    )}
                  </TableBuilderColumn>
                ))}
              </TableBuilder>
            </Cell>
          )}

          {/* Submit Button */}
          {!loading && (
            <Cell span={12}>
              <Button
                onClick={handleCsvSubmit}
                isLoading={submitMutation.isPending}
                overrides={{
                  BaseButton: {
                    style: {
                      width: '100%',
                      padding: '12px 0',
                      backgroundColor: '#276EF1',
                      color: '#fff',
                      fontWeight: 'bold',
                    },
                  },
                }}
              >
                Upload CSV and Submit
              </Button>
            </Cell>
          )}
        </Grid>
      </Card>
    </>
  );
};

export default ApplyCandidateFormBulk;